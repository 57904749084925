<template>
  <Breadcrumb />
  <div class="card">
    <div class="card-header">
      <div
        class="nav card-header-tabs w-100 nav-pills nav-justified pb-2"
        role="tablist"
      >
        <button
          class="nav-link mt-2 active me-1"
          id="nav-0-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-1"
          type="button"
          role="tab"
          aria-selected="true"
        >
          {{
            $t(
              "Projects.ProjectInformation",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</button
        ><button
          class="nav-link mt-2 me-1"
          id="nav-1-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-2"
          type="button"
          role="tab"
          aria-selected="false"
        >
          {{
            $t(
              "Projects.ContractInformations",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button
          class="nav-link mt-2 me-1"
          id="nav-2-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-3"
          type="button"
          role="tab"
          aria-selected="false"
        >
          {{
            $t(
              "Projects.Configurations",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </div>
    </div>
    <div class="card-body">
      <form class="row g-3 form-project-edit" novalidate>
        <div v-if="errors.length > 0" class="alert alert-warning">
          <ul class="mb-0">
            <li v-for="error in errors" v-bind:key="error">
              {{ error }}
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="nav-1" role="tabpanel">
            <div class="row mt-2 mb-2">
              <div class="col-md-6 mb-2">
                <label for="Name" class="form-label required"
                  >{{
                    $t(
                      "Projects.Name",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  id="Name"
                  v-model="projectData.name"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="FormulaName" class="form-label required">{{
                  $t(
                    "Projects.FormulaName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  id="FormulaName"
                  :value="projectData.formulaName"
                  :spellcheck="this.$isTextSpellCheck"
                  disabled
                  required
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="AppName" class="form-label required"
                  >{{
                    $t(
                      "Projects.CompanyName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="AppName"
                  v-model="projectData.appName"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="TaxOffice" class="form-label"
                  >{{
                    $t(
                      "Projects.TaxOffice",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TaxOffice"
                  v-model="projectData.taxOffice"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="TaxNo" class="form-label"
                  >{{
                    $t(
                      "Projects.TaxNo",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="TaxNo"
                  v-model="projectData.taxNo"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="Country" class="form-label"
                  >{{
                    $t(
                      "CompanyInformation.Country",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Country"
                  v-model="projectData.country"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="City" class="form-label"
                  >{{
                    $t(
                      "Projects.City",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="City"
                  v-model="projectData.city"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="County" class="form-label"
                  >{{
                    $t(
                      "Projects.County",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="County"
                  v-model="projectData.county"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="Address" class="form-label"
                  >{{
                    $t(
                      "Projects.Address",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Address"
                  v-model="projectData.address"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="Phone" class="form-label"
                  >{{
                    $t(
                      "Projects.Phone",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Phone"
                  v-model="projectData.phone"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="Fax" class="form-label"
                  >{{
                    $t(
                      "Projects.Fax",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Fax"
                  v-model="projectData.fax"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="ContactPerson" class="form-label"
                  >{{
                    $t(
                      "Projects.ContactPerson",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="ContactPerson"
                  v-model="projectData.contactPerson"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="ContactPersonEmail" class="form-label"
                  >{{
                    $t(
                      "Projects.ContactPersonEmail",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="ContactPersonEmail"
                  v-model="projectData.contactPersonEmail"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="ContactPersonTel" class="form-label"
                  >{{
                    $t(
                      "Projects.ContactPersonTel",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="ContactPersonTel"
                  v-model="projectData.contactPersonTel"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2 mt-2">
                <div class="form-check">
                  <label for="IsActive" class="form-label">{{
                    $t(
                      "BaseModelFields.IsActive",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsActive"
                    v-model="projectData.isActive"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-2" role="tabpanel">
            <div class="row mt-2 mb-2">
              <div class="col-md-6 mb-2">
                <label for="ContractStartDate" class="form-label"
                  >{{
                    $t(
                      "Projects.ContractStartDate",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <DatePicker
                  type="date"
                  id="ContractStartDate"
                  :value="projectData.contractStartDate"
                  :displayFormat="$store.getters._dateFormat"
                />
                <!-- <input
                  type="text"
                  class="form-control text-uppercase"
                  id="ContractStartDate"
                  v-model="projectData.contractStartDate"
                  :spellcheck="this.$isTextSpellCheck"
                /> -->
              </div>
              <div class="col-md-6 mb-2">
                <label for="UnitPriceOfUser" class="form-label"
                  >{{
                    $t(
                      "Projects.UnitPriceOfUser",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  id="UnitPriceOfUser"
                  v-model="projectData.unitPriceOfUser"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="StartUserCount" class="form-label"
                  >{{
                    $t(
                      "Projects.StartUserCount",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  id="StartUserCount"
                  v-model="projectData.startUserCount"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label for="CurrentUserCount" class="form-label"
                  >{{
                    $t(
                      "Projects.CurrentUserCount",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  id="CurrentUserCount"
                  v-model="projectData.currentUserCount"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-3" role="tabpanel">
            <div class="row mt-2 mb-2">
              <div class="col-md-3 mb-2">
                <label class="form-label required"
                  >{{
                    $t(
                      "Projects.Instance",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <FormSelect
                  type="SelectWithRemoteData"
                  @onChange="onChangeForInstance"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  requestUrl="/Lcdp-SummaryInstances"
                  :selectedData="selectedInstance"
                />
              </div>
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsIpControl" class="form-label"
                    >{{
                      $t(
                        "Projects.IsIpControl",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsIpControl"
                    v-model="projectData.isIpControl"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsFormulaErrorLogOpen" class="form-label"
                    >{{
                      $t(
                        "Projects.IsFormulaErrorLogOpen",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsFormulaErrorLogOpen"
                    v-model="projectData.isFormulaErrorLogOpen"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsRecordLogOpen" class="form-label"
                    >{{
                      $t(
                        "Projects.IsRecordLogOpen",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsRecordLogOpen"
                    v-model="projectData.isRecordLogOpen"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsRecordViewLogOpen" class="form-label"
                    >{{
                      $t(
                        "Projects.IsRecordViewLogOpen",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsRecordViewLogOpen"
                    v-model="projectData.isRecordViewLogOpen"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsLabelMultiLanguage" class="form-label"
                    >{{
                      $t(
                        "Projects.IsLabelMultiLanguage",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsLabelMultiLanguage"
                    v-model="projectData.isLabelMultiLanguage"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsTwoFactorAuth" class="form-label"
                    >{{
                      $t(
                        "Projects.IsTwoFactorAuth",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsTwoFactorAuth"
                    v-model="projectData.isTwoFactorAuth"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsApiLogOpen" class="form-label"
                    >{{
                      $t(
                        "Projects.IsApiLogOpen",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsApiLogOpen"
                    v-model="projectData.isApiLogOpen"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col-md-3 mb-2">
                <label class="form-label">&nbsp;</label>
                <div class="form-check">
                  <label for="IsMessageOn" class="form-label"
                    >{{
                      $t(
                        "Projects.IsMessageOn",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsMessageOn"
                    v-model="projectData.isMessageOn"
                  />
                </div>
              </div>
              <div class="col-md-9 mb-2">
                <label for="Message" class="form-label"
                  >{{
                    $t(
                      "Projects.Message",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  id="Message"
                  v-model="projectData.message"
                  :spellcheck="this.$isTextSpellCheck"
                />
              </div>
            </div>
            <div class="row row-cols-1 mt-3">
              <div class="col col-md-12">
                <label for="AcceptIpAddresses" class="form-label"
                  >{{
                    $t(
                      "CompanyInformation.AcceptIpAddresses",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <textarea
                  name="AcceptIpAddresses"
                  class="form-control"
                  id="AcceptIpAddresses"
                  v-model="projectData.acceptIpAddresses"
                  :spellcheck="this.$isTextSpellCheck"
                  rows="2"
                ></textarea>
              </div>
            </div>
            <div class="row row-cols-1 mt-3">
              <div class="col col-md-12">
                <label for="GoogleMapsApiKey" class="form-label"
                  >{{
                    $t(
                      "CompanyInformation.GoogleMapsApiKey",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <textarea
                  name="GoogleMapsApiKey"
                  class="form-control"
                  id="GoogleMapsApiKey"
                  v-model="projectData.googleApiKey"
                  :spellcheck="this.$isTextSpellCheck"
                  rows="2"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="bg-light step-action-buttons p-2">
      <ActionButtons
        classes="d-flex justify-content-center"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "ProjectEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      projectData: {},
      cloneCompany: false,
      selectedInstance: [],
    };
  },
  methods: {
    getProjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format("/Lcdp-ArgesetGet?id={0}", this.$route.params.projectId)
        )
        .then((response) => {
          this.projectData = response.data;
          if (this.projectData.isOk) {
            this.cloneCompany = String.isNullOrWhiteSpace(
              this.projectData.copiedFromPublicId
            )
              ? false
              : true;
            if (
              !String.isNullOrWhiteSpace(response.data.instanceName) &&
              !String.isNullOrWhiteSpace(response.data.instancePublicId)
            ) {
              this.selectedInstance.push({
                key: response.data.instancePublicId,
                value: response.data.instanceName,
              });
            }

            this.$root.preparePhoneNumber(
              "ContactPersonTel",
              this.projectData.contactPersonTel
            );
            this.$root.preparePhoneNumber("Phone", this.projectData.phone);
            this.$root.preparePhoneNumber("Fax", this.projectData.fax);
          } else {
            this.errors.push(this.projectData.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeForInstance: function (selected) {
      this.projectData.instancePublicId = selected.key;
    },
    onSubmit(buttonName) {
      var form = $(".form-project-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-ArgesetEditForSuperAdmin", { ...this.projectData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/Project/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getProjectDetail();
  },
};
</script>
